import { ReactNode } from 'react';
import { cn } from '~/utils/tw';

export type AUTH_BACKGROUND =
  | 'clouds'
  | 'envelope'
  | 'clock'
  | 'handshake'
  | 'paper'
  | 'securitycode'
  | 'building'
  | 'pen'
  | 'code';

type AuthLayoutProps = {
  children: ReactNode;
  sideContent: 'left' | 'right';
  contentSize?: 'sm' | 'md';
  content?: ReactNode;
  background: AUTH_BACKGROUND;
  heroText?: boolean;
};

export default function AuthLayout({
  children,
  sideContent,
  content,
  background = 'clouds',
  heroText = false,
  contentSize = 'md',
}: AuthLayoutProps) {
  const bgImage: Record<AUTH_BACKGROUND, string> = {
    clouds: 'bg-clouds',
    envelope: 'bg-envelope',
    clock: 'bg-clock',
    handshake: 'bg-handshake',
    paper: 'bg-paper',
    securitycode: 'bg-securitycode',
    building: 'bg-building',
    pen: 'bg-pen',
    code: 'bg-code',
  };

  return (
    <div
      className={cn(
        'flex min-h-full w-full flex-col overflow-y-auto md:flex-row',
        {
          'md:flex-row-reverse': sideContent === 'left',
        },
      )}
    >
      <div
        className={cn(
          `flex w-full justify-center md:w-[50%] md:pb-0 lg:w-[60%]`,
          {
            'md:w-[33%] lg:w-[33%]': contentSize === 'sm',
          },
        )}
      >
        <div
          className={`${bgImage[background]} flex h-40 w-full items-center justify-center bg-homy-gray
          bg-cover bg-center bg-no-repeat text-center bg-blend-multiply sm:h-80 md:fixed md:z-[-1] md:h-screen`}
        >
          {contentSize === 'sm' ? (
            <div
              className={cn(
                'flex h-full w-full items-center justify-center pb-4 sm:pb-36 md:hidden md:pb-0',
                {
                  'md:flex md:max-w-[33%]': heroText,
                },
              )}
            >
              {content}
            </div>
          ) : (
            <h1 className="hero-text p-6 text-center md:text-start">
              {content}
            </h1>
          )}
        </div>
      </div>
      <div
        className={cn(
          'flex items-center justify-center bg-white md:w-[50%] lg:w-[40%]',
          {
            'items-start md:w-[67%] lg:w-[67%]': contentSize === 'sm',
          },
        )}
      >
        <div
          className={cn(
            'z-10 mx-6 -mt-4 flex w-full max-w-[500px] justify-center bg-white p-4 shadow-md sm:-mt-10 sm:max-w-[534px] md:-mt-0 md:w-full md:shadow-none ',
            {
              '-mt-6 max-w-[500px] sm:-mt-36 sm:max-w-[696px] md:-mt-0':
                contentSize === 'sm',
            },
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}
